import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import variables from '../variables';

export interface KerbButtonProps extends Omit<ButtonProps, 'variant' | 'size'> {
  /**
   * Is this the principal call to action on the page?
   */
  variant: 'primary' | 'secondary' | 'success' | 'warning';
  /**
   * How large should the button be?
   */
  size?: 'small' | 'full-width';
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.3);',
    height: 40,
    padding: '0 5px',
    minWidth: 200,
    width: '100%',
    maxWidth: 450,
    transition: variables.transition.buttonHoverTransitionOut,
    fontWeight: 600,
    fontFamily: 'Open Sans',
    '&.--small': {
      maxWidth: 200,
    },
    '&.--full-width': {
      maxWidth: '100%',
    },
    '&.--primary': {
      backgroundColor: variables.color.primary,
      background: `linear-gradient(135deg, ${variables.color.primary} 0%, #ffe539 100%)`,
      color: '#292929',
    },
    '&.--secondary': {
      backgroundColor: variables.color.secondary,
      background: `linear-gradient(135deg, ${variables.color.secondary} 0%, #17312b 100%)`,
      color: 'white',
      transition: variables.transition.buttonHoverTransitionOut,

      '&:hover': {
        backgroundColor: variables.color.primary,
        background: `linear-gradient(135deg, ${variables.color.primary} 0%, #ffe539 100%)`,
        color: 'black',
      },
    },
    '&.--success': {
      backgroundColor: variables.color.success,
      background: `linear-gradient(135deg, ${variables.color.success} 0%, #55cc41 100%)`,
      color: 'white',
    },
    '&.--warning': {
      backgroundColor: variables.color.warning,
      background: `linear-gradient(135deg, ${variables.color.warning} 0%, #d2594b 100%)`,
      color: 'white',
    },
    '&:hover': {
      boxShadow: 'none',
      filter: 'brightness(110%)',
      transition: variables.transition.buttonHoverTransitionIn,
    },
    '&:disabled': {
      filter: 'brightness(80%)',
    },
  },
});

/**
 * Primary UI component for user interaction
 */
export const KerbButton: React.FC<KerbButtonProps> = ({
  label,
  variant,
  size,
  ...props
}) => {
  const classes = useStyles();
  const className = `${classes.root} --${variant} --${size}`;

  return (
    <Button className={className} {...props}>
      {label}
    </Button>
  );
};
