import styled, { createGlobalStyle } from 'styled-components';
import OpenSansRegular from '../../shared/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../../shared/fonts/OpenSans-Bold.ttf';

import variables from '../variables';

const { viewportWidths, color } = variables;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans Regular';
    src: url(${OpenSansRegular}) format('truetype');
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Open Sans Bold';
    src: url(${OpenSansBold}) format('truetype');
    font-style: normal;
    font-display: auto;
  }
`;

export const StaticKerbErrorPage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(123deg, #253137 0%, #62777e 100%);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${viewportWidths.tabletWidth}) {
    align-items: center;
    justify-content: flex-start;
  }
`;

export const StaticErrorHeadingContainer = styled.div`
  width: calc(100% - 360px);
  height: calc(100vh - 200px);
  padding-top: 100px;

  @media (max-width: ${viewportWidths.mediumDesktopWidth}) {
    padding: 50px;
    width: calc(100% - 100px);
    height: calc(100vh - 100px);
  }

  @media (max-width: ${viewportWidths.mobileWidth}) {
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100vh - 40px);
  }
`;

export const PrimaryHeading = styled.h1`
  font: normal normal 100px/136px 'Open Sans Regular';
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 3px 5px 3px #00000029;
  margin: 0;
  span.primary-heading__bold {
    display: block;
    font: normal normal 110px/120px 'Open Sans Bold';
    letter-spacing: 1px;
    color: ${color.primary};
    text-shadow: 3px 5px 3px #00000029;
    margin: 0;
    @media (max-width: ${viewportWidths.mobileWidth}) {
      font-size: 60px;
    }
  }
  @media (max-width: ${viewportWidths.mediumDesktopWidth}) {
    font-size: 80px;
  }
  @media (max-width: ${viewportWidths.smallDesktopWidth}) {
    line-height: 100px;
  }
  @media (max-width: ${viewportWidths.mobileWidth}) {
    margin-top: 40px;
    font-size: 50px;
    line-height: 60px;
  }
`;

export const SubHeading = styled.h2`
  font: normal normal 600 50px/68px 'Open Sans Regular';
  letter-spacing: 0px;
  color: ${color.primary};
  text-shadow: 3px 5px 3px #00000029;
  margin: 0;

  @media (max-width: ${viewportWidths.mobileWidth}) {
    margin-top: 20px;
    font-size: 30px;
  }
`;
