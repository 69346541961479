import React from 'react';
import styled from 'styled-components';

import {
  GlobalStyle,
  StaticKerbErrorPage,
  StaticErrorHeadingContainer,
  PrimaryHeading,
  SubHeading,
} from './KerbErrorPage.styles';

export interface KerbErrorPageProps {
  /**
   * Background image used by error page
   */
  backgroundImage?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

const StyledStaticKerbErrorPage = styled(StaticKerbErrorPage)`
  background-image: ${(props: KerbErrorPageProps) =>
      props.backgroundImage && `url(${props.backgroundImage}), `}
    linear-gradient(123deg, #253137 0%, #62777e 100%);
`;

/**
 * Primary UI component for Error Page
 */

export const KerbErrorPage: React.FC<KerbErrorPageProps> = ({
  backgroundImage,
}) => (
  <>
    <GlobalStyle />
    <StyledStaticKerbErrorPage
      className="static-error-page"
      data-testid="background-container"
      backgroundImage={backgroundImage}
    >
      <StaticErrorHeadingContainer className="static-error-page__heading-container">
        <PrimaryHeading className="static-error-page__primary-heading">
          <span className="primary-heading__bold">Oops!</span>
          Looks like something went wrong.
        </PrimaryHeading>
        <SubHeading className="static-error-page__subheading">
          Please try again.
        </SubHeading>
      </StaticErrorHeadingContainer>
    </StyledStaticKerbErrorPage>
  </>
);
