const variables = {
  color: {
    primary: '#fcbc00',
    secondary: '#62777e',
    success: '#009200',
    warning: '#920000',
  },
  transition: {
    buttonHoverTransitionIn: 'all 0.2s ease',
    buttonHoverTransitionOut: 'all 0.2s ease',
  },
  viewportWidths: {
    largeDesktopWidth: '1920px',
    mediumDesktopWidth: '1200px',
    smallDesktopWidth: '992px',
    tabletWidth: '786px',
    mobileWidth: '650px',
    smallMobileWidth: '450px',
  },
};

export default variables;
